// eslint-disable-next-line @nx/enforce-module-boundaries
import {
  DisplayOptions,
  getInAppMessages,
  InAppMessage,
  initializeWithConfig,
  trackInAppClick,
  trackInAppOpen,
  WithJWT,
} from '@iterable/web-sdk';
import { AxiosError, AxiosResponse } from 'axios';
import { IApiService } from 'web_core_library';

const packageName = process.env.NX_PUBLIC_ITERABLE_PACKAGE_NAME || 'www.neuronation.com';

interface IAPITokenResponsePayload {
  result: {
    iterableToken: string;
    expiresIn: number;
    tokenType: 'Bearer';
    userId: number;
    apiKey: string;
  };
}

function instanceOfAxiosError(object: any): object is AxiosError {
  return 'response' in object;
}

export function initService() {
  let ApiService: IApiService;
  let userId: string;
  let userEmail: string;
  let userToken: string;

  let iterableApiKey: string;
  let iterableObject: WithJWT | undefined;

  const init = (ApiServiceInstance: IApiService) => {
    ApiService = ApiServiceInstance;
  };

  const getIterableToken = async () => {
    if (!userId || !userEmail) {
      throw new Error('User details must be set first!');
    }
    console.log('PXX: Getting token', userId, userEmail);
    const tokenResponse = await ApiService.action<IAPITokenResponsePayload>('iterable_auth', {
      method: 'post',
      urlParams: {
        userId,
      },
      data: {
        device: 'web',
      },
    });
    const { iterableToken, apiKey } = tokenResponse.data.result;
    console.log('PXX: token', iterableToken);
    console.log('PXX: apikey', apiKey);
    userToken = iterableToken;
    iterableApiKey = apiKey;
  };

  const getJWTToken = async (): Promise<string> => {
    if (!userToken) {
      await getIterableToken();
    }
    return userToken;
  };

  const initIterable = async () => {
    if (!ApiService) {
      throw new Error('Iterable init must be called first!');
    }

    //Get the apikey if needed
    if (!iterableApiKey) {
      await getIterableToken();
    }

    // Here we call initialize with JWT token.
    try {
      const result = initializeWithConfig({
        authToken: iterableApiKey,
        configOptions: {
          isEuIterableService: false,
        },
        generateJWT: getJWTToken,
      });
      iterableObject = result;
      iterableObject.setEmail(userEmail);
      iterableObject.setUserID(userId);
    } catch (err) {
      console.error('Error initializing Iterable');
    }
  };

  const setUserDetails = (email: string, uId: number) => {
    userId = `${uId}`;
    userEmail = email;
  };

  const getMessages = async (): Promise<Partial<InAppMessage>[]> => {
    try {
      const result = await getInAppMessages(
        {
          count: 50,
          packageName: packageName,
        },
        { display: DisplayOptions.Deferred }
      );
      const messages = await result.request();
      return messages.data.inAppMessages;
    } catch (err) {
      if (err instanceof Error && instanceOfAxiosError(err) && (err.response as AxiosResponse).status === 401) {
        console.error('User is not created in Iterable yet');
        return [];
      }
    }
    return [];
  };

  const setMessageRead = async (messageId: string) => {
    trackInAppOpen({
      messageId: messageId,
      deviceInfo: { appPackageName: packageName },
    });
  };

  const setMessageClicked = (messageId: string, buttonId?: string) => {
    trackInAppClick({
      messageId: messageId,
      clickedUrl: buttonId,
      deviceInfo: { appPackageName: packageName },
    });
  };

  return {
    init,
    setUserDetails,
    getMessages,
    initIterable,
    setMessageRead,
    setMessageClicked,
  };
}

const service = initService();
export default service;
