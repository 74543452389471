import './common/polyfills';
import {
  ELanguage,
  ETagType,
  LanguageActions,
  LanguageProvider,
  LanguageReducer,
  LanguageSaga,
  LanguageSelectors,
  LanguageService,
  TFunction,
  parseMultilineLoca,
  renderMultilineLoca,
  renderStringToReact,
  useDocumentLang,
  useTranslation,
} from './lib/language';
import {
  StatsActions,
  StatsReducer,
  StatsSaga,
  StatsSelectors,
  preloadAgeGroupSaga,
  preloadStatsSaga,
} from './lib/stats';

import {
  ExperimentsActions,
  ExperimentsReducer,
  ExperimentsSaga,
  ExperimentsSelectors,
  waitForExperimentsFeature,
} from './lib/experiments';

import {
  CookieConsent,
  FirebaseActionTypes,
  FirebaseActions,
  FirebaseReducer,
  FirebaseSaga,
  FirebaseSelectors,
  FirebaseTypes,
  MessagingController,
  RemoteConfigService,
  usePushMessages,
  waitForFirebaseReadySaga,
} from './lib/firebase';

import { base64UrlToString, stringToBase64URL } from './common/base64String';
import { checkPasswordComplexity, isLocalhost, isMobile, isProduction } from './common/utils';
import { NotificationActions, NotificationSaga, parseError } from './lib/notifications';
import SentryService from './lib/sentry';

import {
  ProgressActions,
  ProgressReducer,
  ProgressSaga,
  ProgressSelectors,
  waitForFullProgressSaga,
} from './lib/progress';

import { HistogramActions, HistogramReducer, HistogramSaga, HistogramSelectors } from './lib/histogram';

import {
  AssessmentEvaluationActions,
  AssessmentEvaluationReducer,
  AssessmentEvaluationSaga,
} from './lib/assessmentEvaluation';

import type { IDomainCalculation } from './lib/assessmentEvaluation';
import { calculateDomainValues } from './lib/assessmentEvaluation';

import CookieManager from './common/cookieManager';
import { EDomain } from './common/types';
import { AuthActions, AuthReducer, AuthRoles, AuthSaga, AuthSelectors, AuthService, AuthTypes } from './lib/auth';
import { ConfigActions, ConfigReducer, ConfigSaga, ConfigSelectors, ConfigTypes, getWorkoutId } from './lib/config';
import { getSessionFocusDomain } from './lib/cycle';
import AttributionService, {
  EUrlParamName,
  IAttribution,
  IUrlParams,
  TParamValue,
} from './lib/firebase/attributionService';

import logger, { EErrorLevel } from './common/logger';
import { CacheBuster } from './lib/auth/utils';
import {
  IInAppMessageBase,
  InAppMessage,
  IterableActions,
  IterableReducer,
  IterableSaga,
  IterableSelectors,
} from './lib/iterable';
import {
  PasskeyAuthActions,
  PasskeyAuthReducer,
  PasskeyAuthSaga,
  PasskeyAuthSelectors,
  PasskeyConfirmation,
} from './lib/passkeyAuth';

export {
  AssessmentEvaluationActions,
  AssessmentEvaluationReducer,
  AssessmentEvaluationSaga,
  AttributionService,
  AuthActions,
  AuthReducer,
  AuthRoles,
  AuthSaga,
  AuthSelectors,
  AuthService,
  AuthTypes,
  CacheBuster,
  ConfigActions,
  ConfigReducer,
  ConfigSaga,
  ConfigSelectors,
  ConfigTypes,
  CookieConsent,
  CookieManager,
  EDomain,
  EErrorLevel,
  ELanguage,
  ETagType,
  EUrlParamName,
  ExperimentsActions,
  ExperimentsReducer,
  ExperimentsSaga,
  ExperimentsSelectors,
  FirebaseActionTypes,
  FirebaseActions,
  FirebaseReducer,
  FirebaseSaga,
  FirebaseSelectors,
  FirebaseTypes,
  HistogramActions,
  HistogramReducer,
  HistogramSaga,
  HistogramSelectors,
  LanguageActions,
  LanguageProvider,
  LanguageReducer,
  LanguageSaga,
  LanguageSelectors,
  LanguageService,
  MessagingController,
  NotificationActions,
  NotificationSaga,
  PasskeyAuthActions,
  PasskeyAuthReducer,
  PasskeyAuthSaga,
  PasskeyAuthSelectors,
  PasskeyConfirmation,
  ProgressActions,
  ProgressReducer,
  ProgressSaga,
  ProgressSelectors,
  RemoteConfigService,
  SentryService,
  StatsActions,
  StatsReducer,
  StatsSaga,
  StatsSelectors,
  base64UrlToString,
  calculateDomainValues,
  checkPasswordComplexity,
  getSessionFocusDomain,
  getWorkoutId,
  isLocalhost,
  isMobile,
  isProduction,
  logger,
  parseError,
  parseMultilineLoca,
  preloadAgeGroupSaga,
  preloadStatsSaga,
  renderMultilineLoca,
  renderStringToReact,
  stringToBase64URL,
  useDocumentLang,
  usePushMessages,
  useTranslation,
  waitForExperimentsFeature,
  waitForFirebaseReadySaga,
  waitForFullProgressSaga,
};

export { InAppMessage, IterableActions, IterableReducer, IterableSaga, IterableSelectors };

export type { IAttribution, IDomainCalculation, IInAppMessageBase, IUrlParams, TFunction, TParamValue };
