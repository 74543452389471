import logger from '../../common/logger';
import {
  AuthenticationCredential,
  PublicKeyCredentialCreationOptionsJSON,
  PublicKeyCredentialRequestOptionsJSON,
  RegistrationCredential,
  TLoginMode,
} from './types';
import { convertCreationOptions, convertCredentialRequest } from './utils';

export async function isBrowserSupported() {
  // Availability of `window.PublicKeyCredential` means WebAuthn is usable.
  // `isUserVerifyingPlatformAuthenticatorAvailable` means the feature detection is usable.
  if (
    window.PublicKeyCredential &&
    PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable &&
    PublicKeyCredential.isConditionalMediationAvailable
  ) {
    // Check if user verifying platform authenticator is available.
    const authenticatorAvailable = await PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable();
    const conditionalAvailable = await PublicKeyCredential.isConditionalMediationAvailable();
    return authenticatorAvailable && conditionalAvailable;
  }
  return false;
}

export async function createNewPasskey(publicKey: PublicKeyCredentialCreationOptionsJSON) {
  const options = convertCreationOptions(publicKey);
  const credential = await navigator.credentials.create(options);
  return credential as RegistrationCredential | null;
}

export async function loginWithPasskey(publicKey: PublicKeyCredentialRequestOptionsJSON, mode?: TLoginMode) {
  const options = convertCredentialRequest(publicKey, mode);
  logger(['credential request options:', options]);
  const credential = await navigator.credentials.get(options);
  return credential as AuthenticationCredential | null;
}

const AuthService = {
  isBrowserSupported,
  createNewPasskey,
  loginWithPasskey,
};
export default AuthService;
