import { Dialog } from '@web_opxp/components';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import './InAppMessage.scss';
import * as IterableSelectors from './selectors';
import { IAppState } from './selectors';
import { IterableActions } from './slice';
import { IInAppMessageBase } from './types';
import { removeSlashes } from './utils';

const CTA_BUTTON_ID = 'nn-iterable-cta';
const CLOSE_BUTTON_ID = 'nn-iterable-close';

interface IInAppMessageOwnProps {
  page: string;
}

export interface IInAppMessageFCProps extends IInAppMessageOwnProps {
  started: boolean;
  message?: IInAppMessageBase;
  onSetMessageRead: (messageId: string) => void;
  onSetMessageClicked: (messageId: string, buttonId?: string) => void;
}

export const InAppMessageFC: React.FC<IInAppMessageFCProps> = ({
  started,
  onSetMessageRead,
  onSetMessageClicked,
  message,
  page,
}) => {
  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    setIsOpen(true);
  }, [page]);

  if (!started || !message) return null;

  const displayedMessage = message.html || '';
  const buttonsPresent = displayedMessage.includes(CTA_BUTTON_ID) || displayedMessage.includes(CLOSE_BUTTON_ID);
  const header = buttonsPresent ? undefined : ' ';
  const messageDiv = <div dangerouslySetInnerHTML={{ __html: displayedMessage }}></div>;
  const messageId = message.messageId || '';

  const handler = (element: any) => {
    if (element.target) {
      if (element.target.id === CTA_BUTTON_ID) {
        // CTA button is clicked
        onSetMessageClicked(messageId, CTA_BUTTON_ID);
        setIsOpen(false);
      }
      if (element.target.id === CLOSE_BUTTON_ID) {
        // Close button is clicked
        onSetMessageRead(messageId);
        setIsOpen(false);
      }
    }
    // Somewhere else clicked
    onSetMessageRead(messageId);
    setIsOpen(false);
  };

  const handleClose = () => {
    onSetMessageRead(messageId);
    setIsOpen(false);
  };

  return (
    <Dialog
      bodyClassName="inapp-message-body"
      isOpen={isOpen}
      usePortal={true}
      showCloseButton={!buttonsPresent}
      headerContent={header}
      canOutsideClickClose={true}
      onClose={handleClose}>
      <div onClick={handler}>{messageDiv}</div>
    </Dialog>
  );
};

const mapStateToProps = (state: IAppState, ownProps: IInAppMessageOwnProps) => {
  const page = ownProps.page === '/' ? 'dashboard' : removeSlashes(ownProps.page);
  const message = IterableSelectors.getNextMessage(state, page);
  const started = IterableSelectors.isStarted(state);
  return {
    message,
    started,
  };
};

const mapDispatchToProps = {
  onSetMessageRead: IterableActions.setMessageRead,
  onSetMessageClicked: IterableActions.setMessageClicked,
};

const InAppMessage = connect(mapStateToProps, mapDispatchToProps)(InAppMessageFC);

export default InAppMessage;
