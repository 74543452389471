// eslint-disable-next-line @nx/enforce-module-boundaries
import { InAppMessage } from '@iterable/web-sdk';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IInAppMessageBase, ISetMessageClickedPayload } from './types';
import { processInAppMessage } from './utils';

export interface IIterableState {
  started: boolean;
  // This any type is necessary or else a type error is thrown.
  // We should create an own type and store only data which is needed for the messages
  messages: IInAppMessageBase[];
}

export const initialState: IIterableState = {
  started: false,
  messages: [],
};

export const statePropName = 'iterable';

const iterableSlice = createSlice({
  name: statePropName,
  initialState,
  reducers: {
    initIterable: (state: IIterableState) => {
      // Handle language initialization if needed
    },
    iterableStarted: (state: IIterableState) => {
      state.started = true;
    },
    getMessages: (state: IIterableState) => {},
    storeMessages: (state: IIterableState, action: PayloadAction<Partial<InAppMessage>[]>) => {
      state.messages = action.payload.map((message) => processInAppMessage(message));
    },
    setMessageRead: (state: IIterableState, action: PayloadAction<string>) => {
      state.messages = state.messages.filter((message) => message.messageId !== action.payload);
    },
    setMessageClicked: {
      reducer: (state: IIterableState, action: PayloadAction<ISetMessageClickedPayload>) => {
        state.messages = state.messages.filter((message) => message.messageId !== action.payload.messageId);
      },
      prepare: (messageId: string, buttonId?: string) => {
        return {
          payload: {
            messageId,
            buttonId,
          },
        };
      },
    },
  },
});

export const IterableActions = iterableSlice.actions;
export default iterableSlice.reducer;
